<template>
  <a v-if="href" :href="href" rel="nofollow" @click="bookNow">
    <slot/>
  </a>
  <button v-else type="button" @click="bookNow">
    <slot/>
  </button>
</template>

<script setup>
import useClipboard from "vue-clipboard3"

const props = defineProps({
  href: String,
  coupon: String,
})

const { toClipboard } = useClipboard()

const bookNow = () => {
  if (props.coupon) {
    copyDiscount(props.coupon)
  }
}

const copyDiscount = (code) => {
  toClipboard(code).then(() => {
    toast.success("Discount code copied to clipboard")
  })
}
</script>

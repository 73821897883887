<template>
  <menu-item
    as="button"
    :class="[
      'group flex w-full items-center gap-2 p-2 text-sm text-slate rounded-md',
      'ui-active:bg-sky-600 ui-active:text-white',
    ]"
    @click="removeTrip"
  >
    <plus-icon class="w-5 h-5" aria-hidden="true"/>
    Add to your trips
  </menu-item>
</template>

<script setup>
import { MenuItem } from "@headlessui/vue"
import { PlusIcon } from "@heroicons/vue/20/solid"

const props = defineProps({
  id: {
    type: String,
    required: true,
  },
})

const removeTrip = () => {
  axios.post(`/@${coworkations.user.username}/trips`, { id: props.id }).then(() => {
    toast.success("Saved trip to your list")
  }, () => {
    toast.error("There was a problem adding the trip to your list")
  })
}
</script>

<template>
  <menu-item
    as="button"
    :class="[
      'group flex w-full items-center gap-2 p-2 text-sm text-slate rounded-md',
      'ui-active:bg-red-500 ui-active:text-white',
    ]"
    @click="removeTrip"
  >
    <trash-icon class="w-5 h-5" aria-hidden="true"/>
    Remove trip
  </menu-item>
</template>

<script setup>
import { MenuItem } from "@headlessui/vue"
import { TrashIcon } from "@heroicons/vue/20/solid"

const props = defineProps({
  id: {
    type: String,
    required: true,
  },
})

const removeTrip = () => {
  axios.delete(`/@${coworkations.user.username}/trips/${props.id}`).then(() => {
    toast.success("Removed trip from your list")

    window.location.reload()
  }, () => {
    toast.error("There was a problem removing the trip from your list")
  })
}
</script>
